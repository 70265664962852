import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { setDigitalCollectionUserPreference } from '../helpers/localStorageHelper';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AuthContext } from 'src/context/AuthProvider';
import TermsOfServiceDialog from 'src/components/Modals/TermsOfServiceDialog';
import DigitalCollectionTermsDialog from 'src/components/Modals/DigitalCollectionTermsDialog';
import ConfirmEmailInfo from './Modals/ConfirmEmailInfo';

function AuthGuard({ children }) {
  const user = useContext(AuthContext);
  const router = useRouter();
  const [shouldShowTermsOfService, setShouldShowTermsOfService] = useState(false);
  const [shouldShowDigitalCollectionTerms, setShouldShowDigitalCollectionTerms] = useState(false);
  const [shouldShowConfirmEmailInfo, setShouldShowConfirmEmailInfo] = useState(false);

  const { data: session } = useSession();

  const handleCloseTermsOfServiceDialog = (event, reason) => {
    if (reason && reason !== 'backdropClick') {
      user.updateTermsAndAgreement();
      setShouldShowTermsOfService(false);
    }
  };
  const handleCloseDigitalCollectionTermsDialog = async (event, reason, acceptedDate) => {
    if (reason && reason !== 'backdropClick') {
      if (user.settings !== null) user.settings.digitalCollectionAccepted = acceptedDate;
      try {
        await user.updateDigitalCollectionTerms();

        setShouldShowDigitalCollectionTerms(false);
        if (reason === 'Accepted') {
          setDigitalCollectionUserPreference(session.user.id, 'yes');
          setShouldShowConfirmEmailInfo(true);
        } else {
          setDigitalCollectionUserPreference(session.user.id, 'no');
        }
      } catch (error) {}
    }
  };
  const handleCloseConfirmEmailInfo = () => {
    setShouldShowConfirmEmailInfo(false);
  };

  useEffect(() => {
    if (!user.isLoading) {
      if (!user.isLoggedIn) {
        sessionStorage.setItem('redirectUrl', window.location);
        router.push('/');
      } else if (user.needsToAcceptTerms) {
        setShouldShowTermsOfService(true);
      } else if (!user.hasDigitalCollectionTerms) {
        setShouldShowDigitalCollectionTerms(true);
      } else if (
        // If the payer has old contact info we want him to take a look at it,
        // it will automatically be marked as verified when the settings page is opened
        user.isLoggedIn &&
        !user.needsToAcceptTerms &&
        user.settings &&
        user.settings.existsAsAPayer &&
        user.settings.hasContactInfo &&
        !user.settings.isEverythingVerified &&
        router.asPath !== '/stillingar'
      ) {
        router.push('/stillingar');
      }
    }
  }, [user, router]);

  if (user.isLoading || shouldShowTermsOfService || shouldShowDigitalCollectionTerms) {
    return (
      <Box sx={{ background: '#f0f0f0 !important', height: '100%', width: '100%' }}>
        <Box sx={{ position: 'absolute', m: '0px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress />
          <DigitalCollectionTermsDialog
            handleClose={handleCloseDigitalCollectionTermsDialog}
            isOpen={shouldShowDigitalCollectionTerms}
          />
          <TermsOfServiceDialog handleClose={handleCloseTermsOfServiceDialog} isOpen={shouldShowTermsOfService} />
          <Typography sx={{ pt: '45px', transform: 'translate(-35%, -50%)' }} variant="body1">
            Vinn úr auðkenningu...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <React.Fragment>
      {user.isLoggedIn ? children : null}
      {user.isLoggedIn && (
        <ConfirmEmailInfo handleClose={handleCloseConfirmEmailInfo} isOpen={shouldShowConfirmEmailInfo} />
      )}
    </React.Fragment>
  );
}

export default AuthGuard;
