import base64ToArrayBuffer from './base64ToArrayBuffer';
import getMimeType from './getMimeType';

export default function showFileInBrowser(data, filename) {
  const blob = new Blob([base64ToArrayBuffer(data)], {
    type: getMimeType(filename)
  });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename); // IE fix
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
