import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

export default function DialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle sx={theme => ({m: '0px', p: theme.spacing(2), fontSize: 28, lineHeight: 1.2})} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={theme => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]})}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
};