import axios from 'axios';

const instance = axios.create();

const setAuthToken = (token) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const api = {
  get(url, params = {}) {
    return instance.get(url, {
      params,
    });
  },
  post(url, body) {
    return instance.post(url, body, {});
  },
  put(url, body) {
    return instance.put(url, body, {});
  },
  delete(url) {
    return instance.delete(url, {});
  },
};

export { api, setAuthToken };
