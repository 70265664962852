import { api } from 'src/api/api';
import { load, isUserFriendlyError } from 'src/helpers/apiHelper';
import { loadClaimErrorMessage } from 'src/helpers/defaultErrorMessages';

export function loadClaim(claimId, isLoading, onSuccess, onError) {
  return api.get(`/api/claim/${claimId}`).then(
    (response) => {
      if (isUserFriendlyError(response)) {
        onError({ message: response.data.errorMessage });
      } else onSuccess(response.data);
      isLoading(false);
    },
    (error) => {
      const response = { error };
      onError({
        message: isUserFriendlyError(response) ? response.data.errorMessage : loadClaimErrorMessage,
      });
      isLoading(false);
    }
  );
}

export function loadClaimHistory(claimId, isLoading, onSuccess, onError) {
  return load(`/api/claim/${claimId}/history`, isLoading, onSuccess, onError);
}

export function loadClaimInvoices(claimId, isLoading, onSuccess, onError) {
  return load(`/api/claim/${claimId}/invoices`, isLoading, onSuccess, onError);
}

export function loadClaimPayments(claimId, isLoading, onSuccess, onError) {
  return load(`/api/claim/${claimId}/payments`, isLoading, onSuccess, onError);
}

export function loadClaimIssues(claimId, isLoading, onSuccess, onError) {
  return load(`/api/claim/${claimId}/issues`, isLoading, onSuccess, onError);
}

export function loadClaimAttachments(claimId, isLoading, onSuccess, onError) {
  return load(`/api/claim/${claimId}/attachments`, isLoading, onSuccess, onError);
}

export function loadOpenClaims(isLoading, onSuccess, onError) {
  return load(`/api/claim/open`, isLoading, onSuccess, onError);
}

export function loadPaidClaims(isLoading, onSuccess, onError) {
  return load(`/api/claim/paid`, isLoading, onSuccess, onError);
}

export function loadPaymentHistory(isLoading, onSuccess, onError) {
  return load(`/api/claim/paymenthistory`, isLoading, onSuccess, onError);
}

export function postponeClaim(body, isLoading, onSuccess, onError) {
  isLoading(true);
  return api
    .post('/api/claimaction/postpone', body)
    .then((response) => {
      if (isUserFriendlyError(response)) {
        onError(response.data.errorMessage);
      } else {
        onSuccess(response.data); 
      }
      isLoading(false);
    })
    .catch(() => {
      isLoading(false);
      onError();
    });
}
