import moment from 'moment';
import 'moment/locale/is';

export function getToday() {
  var mom = moment().utc().startOf('date');
  return mom;
}

export function convertStrToUtcDate(str) {
  var mom = moment.utc(str);
  return mom;
}

export function firstDayOfNextMonth() {
  var mom = getToday();
  mom.add(1, 'month').startOf('month');
  return mom;
}

export function lastDayOfThisMonth() {
  var mom = getToday();
  mom.endOf('month');
  return mom;
}

export function addDays(date, days) {
  var clone = moment(date);
  clone.add(days, 'days');
  return clone;
}

export function dateEquals(date1, date2) {
  return moment(date1).startOf('date').isSame(moment(date2).startOf('date'));
}

export function parseDate(dateString) {
  return moment(dateString, 'YYYY-MM-DD').utc();
}