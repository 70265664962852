import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, TextField } from '@mui/material/';
import AddAttachment from 'src/components/Attachments/AddAttachment';
import issuesStyles from 'styles/issues.module.css';

class MessageForm extends Component {
  static propTypes = {
    message: PropTypes.shape({
      text: PropTypes.string.isRequired,
      attachments: PropTypes.array.isRequired,
    }).isRequired,
    canAddAttachment: PropTypes.bool,
    canConfirm: PropTypes.bool.isRequired,
    handleAddAttachments: PropTypes.func.isRequired,
    handleMessageChange: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canAddAttachment: true,
  };

  render() {
    const { canAddAttachment } = this.props;
    return (
      <Box sx={{width: '600px', maxWidth: '100%'}}>
        <TextField
          id="message"
          label="Skilaboð"
          fullWidth
          sx={{ mb: '8px' }}
          multiline
          minRows="6"
          maxRows="10"
          value={this.props.message.text}
          onChange={this.props.handleMessageChange}
        />
        {canAddAttachment ? (
          <Box
            className={issuesStyles.attachmentDropZone}
            sx={{m: '16px 0px' }}
          >
            <AddAttachment
              attachments={this.props.message.attachments}
              addAttachments={this.props.handleAddAttachments}
            />
          </Box>
        ) : null}
        <Box sx={{textAlign: 'right' }}>
          <Button onClick={this.props.handleClose} sx={{ mr: '8px' }}>
            Hætta við
          </Button>
          <Button
            variant="contained"
            onClick={this.props.handleConfirm}
            disabled={!this.props.canConfirm}
          >
            Senda
          </Button>
        </Box>
      </Box>
    );
  }
}

export default MessageForm;
