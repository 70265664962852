import {api} from 'src/api/api';
import { load, isUserFriendlyError } from 'src/helpers/apiHelper';
import showFileInBrowser from 'src/helpers/showFileInBrowser';

export function loadIssues(isLoading, onSuccess, onError) {
  return load(`/api/issues`, isLoading, onSuccess, onError);
}

export function getIssue(issueId, onSuccess, onError) {
  api
    .get(`/api/issues/${issueId}`)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
}

export function getAttachment(filename, id, type) {
  if (typeof fileName === 'undefined') {
    return;
  }
  api
    .get(`/api/attachments/${id}/${type}`)
    .then((response) => {
      showFileInBrowser(response.data.data, filename);
    })
    .catch((error) => {
      throw error;
    });
}

export function getMessageAttachment(
  communicationAttachmentId,
  attachmentOrigin
) {
  api
    .get(
      `/api/issues/attachments/${communicationAttachmentId}?origin=${attachmentOrigin}`
    )
    .then((response) => {
      showFileInBrowser(response.data.data, response.data.filename);
    })
    .catch((error) => {
      throw error;
    });
}

export function createIssue(issue, isLoading, onSuccess, onError) {
  isLoading(true);
  return api.post(`/api/issues/`, issue).then(
    (response) => {
      if (isUserFriendlyError(response)) {
        onError({ message: response.data.errorMessage });
      } else onSuccess(response);

      isLoading(false);
    },
    (error) => {
      const { response } = error;
      onError({
        message: isUserFriendlyError(response)
          ? response.data.errorMessage
          : 'Villa kom upp við að stofna samskipti. Vinsamlegast reynið aftur.',
      });
      isLoading(false);
    }
  );
}

export function markIssueSeen(issueId) {
  return api.put(`/api/issues/${issueId}/markseen`);
}

export function respondToIssue(issueId, message, onSuccess, onError) {
  return api.post(`/api/issues/${issueId}/messages`, message).then(
    (response) => {
      if (isUserFriendlyError(response)) {
        onError({ message: response.data.errorMessage });
      } else onSuccess(response);
    },
    (error) => {
      const { response } = error;
      onError({
        message: isUserFriendlyError(response)
          ? response.data.errorMessage
          : 'Villa kom upp við að svara samskiptum. Vinsamlegast reynið aftur.',
      });
    }
  );
}
