export const allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];

export default function getMimeType(filename) {
  const ext = filename.split('.').pop().toLowerCase();
  switch (ext) {
    case 'pdf':
      return 'application/pdf';
    case 'html':
    case 'htm':
      return 'text/html';
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg';
    case 'bmp':
      return 'image/bmp';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'doc':
      return 'application/msword';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    default:
      return 'text/plain';
  }
}
