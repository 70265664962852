const products = {
  PrimaryCollection: {
    name: 'Fruminnheimta',
    value: 'PrimaryCollection',
    code: 'F',
    color: '#AED581',
  },
  SecondaryCollection: {
    name: 'Milliinnheimta',
    value: 'SecondaryCollection',
    code: 'M',
    color: '#FFEB3B',
  },
  LegalCollection: {
    name: ' Lögheimta',
    value: 'LegalCollection',
    code: 'L',
    color: '#E57373',
  },
  LegalCollection2: {
    name: ' Lögheimta',
    value: 'LegalCollection2',
    code: 'L',
    color: '#E57373',
  },
  DebtSurveillance: {
    name: 'Kröfuvakt',
    value: 'DebtSurveillance',
    code: 'K',
    color: '#BA68C8',
  },
  ForeignCollection: {
    name: 'Millilandainnheimta',
    value: 'ForeignCollection',
    code: 'E',
    color: '#4DD0E1',
  },
  CurrencyTurnover: {
    name: 'Myntvelta',
    value: 'CurrencyTurnover',
    code: 'My',
    color: '#64B5F6',
  },
};

export function getProducts() {
  return Object.values(products).filter((x) => {
    return x.value !== 'CurrencyTurnover' && x.value !== 'ForeignCollection';
  });
}

export default function getProduct(number) {
  return products[number];
}
