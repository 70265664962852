import { createTheme } from '@mui/material/styles';

const theme = createTheme({
 palette: {
    mode: 'light',
    primary: {
      main: '#283C32',
      dark: '#375046',
    },
    secondary: {
      main: '#73AF69',
    },
    lightgrey: {
      main: '#eeeeee',
    },
    mediumgrey: {
      main: '#909090',
    },
    darkgrey: {
      main: '#666666',
    },
    basicblack: {
      main: '#333333',
    },
    background: {
      default: 'lightgrey',
      paper: '#ffffff',
    },
    danger: {
      main: '#B30000',
    },
    success: {
      main: '#008024',
    },
    info: {
      main: '#024DBC',
    },
    warning: {
      main: '#FD7E14',
    },
    error: {
      main: '#CD5F3C',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontWeight: 400,
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: 'rgb(255, 255, 255)',
          userSelect: 'none',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 11,
          borderRadius: '50%',
          height: '22px',
          width: '22px',
          marginRight: '1px'
        }
      }
    }
  },
  color: {
    motusGreen: '#283C32',
  },
  root: {
    fontSize: 14,
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    h1: {
      fontWeight: 300,
      fontSize: 28,
      margin: '40px 30px 15px 30px',
      '@media print': {
        marginLeft: '0px',
      },
    },
    h2: { fontWeight: 300, fontSize: 28 },
    h3: { fontWeight: 200, fontSize: 24 },
    h4: { fontWeight: 400, fontSize: 18 },
    h5: { fontWeight: 300, fontSize: 16 },
    h6: { fontWeight: 300, fontSize: 14 },
    subtitle1: {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 200,
    },
    body1: {
      fontSize: 14,
      fontWeight: 300,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      fontWeight: 300,
    },
    button: { fontSize: 14 },
  },
});

export default theme;
