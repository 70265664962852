import { isL2Claim } from './claimHelper';

export const issueType = {
  Request: 'Request',
  PayerAgreementRequest: 'PayerAgreementRequest',
  InvoiceRequestedRequest: 'InvoiceRequestedRequest',
  PayerObjectsRequest: 'PayerObjectsRequest',
};

export const PayerPersonalInfoRequest = 'PayerPersonalInfoRequest';

export function getDescription(name) {
  switch (name) {
    case 'Request':
      return 'Almenn fyrirspurn';
    case 'PayerAgreementRequest':
      return 'Óska eftir greiðsludreifingu';
    case 'InvoiceRequestedRequest':
      return 'Óska eftir afriti reiknings';
    case 'PayerObjectsRequest':
      return 'Mótmæli';
    default:
      return 'Athugasemd';
  }
}

export const issueTypes = (claimIds) => {
  const types = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in issueType) {
    if (Object.prototype.hasOwnProperty.call(issueType, key)) {
      types.push(issueType[key]);
    }
  }

  // you can only request agreement through the communication system for IL+ claims,
  // otherwise you can make agreements yourself on the web
  if (claimIds.some((x) => !isL2Claim(x))) {
    return types.filter((x) => x !== issueType.PayerAgreementRequest);
  }
  return types;
};

export function getAll() {
  return Object.keys(issueType);
}
