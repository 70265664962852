import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material/';
import DialogTitle from './DialogTitle';
import { getInfoDialogClosed, setInfoDialogClosed } from 'src/helpers/localStorageHelper';
import { infoMessage } from 'src/helpers/infoMessage';

const oneHour = 60*60*1000;

const shouldShowDialog = (dialogClosed) => {
  if (!dialogClosed) return true;
  return (Date.now() - dialogClosed) > oneHour;
}

export default function InfoDialog() {
  const [isOpen, setIsOpen] = useState(shouldShowDialog(getInfoDialogClosed()));

  if (!infoMessage) return null;

  const handleClose = () => {
    setInfoDialogClosed(Date.now())
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="info-dialog-title"
      >
        <DialogTitle id="info-dialog-title" onClose={handleClose}>
          Vinsamlega athugið
        </DialogTitle>
        <DialogContent sx={(theme) => ({ m: '0px', p: theme.spacing(2), pt: '0px' })}>
        <Typography>
          {infoMessage}
        </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Loka
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}