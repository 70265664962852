export function checkEvent(event) {
  return (
    event === undefined || event.target === undefined || event.target === null
  );
}

export function isEventKeyCodeNumber(event) {
  return (
    (event.keyCode >= 48 && event.keyCode <= 57) ||
    (event.keyCode >= 96 && event.keyCode <= 105)
  );
}

export function isEventKeyCodeTab(event) {
  return event.keyCode === 9;
}

export function isEventKeyCodeEnter(event) {
  return event.keyCode === 13;
}

export function isEventKeyCodeEsc(event) {
  return event.keyCode === 27;
}
