import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import styles from './menu.module.css';

const MenuItem = ({ name, url, current }) => {
  const text = (
    <ListItemText
      primary={name}
      classes={{
        primary: current ? styles.selectedMenuItemText : styles.menuItemText,
      }}
      className={styles.menuItemWrapper}
    />
  );

  return (
    <Link href={url} passHref>
      <ListItem color="primary">
        {text}
      </ListItem>
    </Link>
  );
};

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  current: PropTypes.bool,
};

MenuItem.defaultProps = {
  url: '/',
  current: false,
};

export default MenuItem;
