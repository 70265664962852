import DialogTitle from './DialogTitle';
import PropTypes from 'prop-types';
import { Modal, DialogContent, Divider, Paper, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export default function ConfirmEmailInfo({ handleClose, isOpen }) {
  return (
    <Modal onClose={handleClose} open={isOpen} sx={{ overflow: 'scroll' }} disableEscapeKeyDown>
      <Paper sx={{ padding: '40px', maxWidth: '800px', margin: '60px auto' }}>
        <Grid>
          <DialogTitle fontWeight="bolder" id="customized-dialog-title" onClose={handleClose}>
            Staðfesta netfang
          </DialogTitle>
        </Grid>
        <Divider />
        <DialogContent>
          <Grid mb="50px">
            <Typography variant="h4">
              Nú áttu aðeins eftir að opna tölvupóstinn þinn og staðfesta netfangið þitt með því að smella á hlekkinn í
              póstinum sem við sendum þér.
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button
              onClick={handleClose}
              sx={{ backgroundColor: '#eeeeee', color: '#000000', mr: '10px', width: '110px' }}
            >
              LOKA
            </Button>
          </Grid>
        </DialogContent>
      </Paper>
    </Modal>
  );
}

ConfirmEmailInfo.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
