const phoneNumberPattern = /^[0-9]{3,12}$/;
const icelandicPhoneNumberPattern = /^[4-8]{1}[0-9]{6}$/;

const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
const commonEmailDomains = [
  "@gmail.com", "@yahoo.com", "@hotmail.com", "@outlook.com", "@icloud.com", "@live.com", 
  "@googlemail.com", "@inbox.lv", "@wp.pl", "@me.com", "@vp.pl", "@simnet.is", "@internet.is", 
  "@tuta.com", "@tutanota.com", "@proton.me", "@icelandair.is", "@hi.is", "@motus.is",
];


/**
 * Simple Email validation. Less strict than the server, but catches most nonsense.
 * @param {string} email
 * @returns {number} 1 if email is null or empty, 
 * 2 if email is not a valid email format, 
 * 0 if the email is valid with a common domain,
 * -1 if email is valid with an uncommon domain.
 */
export function validateEmail(email) {
  if (!email || email === '') return 1;
  if (!emailPattern.test(email.trim())) return 2;

  for (var domain of commonEmailDomains) {
    if (email.endsWith(domain)) return 0; // valid email with common domain
  }

  return -1;
}


/**
 * @param {string} phoneNumber
 * @returns {number} 1 if phoneNumber is null or empty, 2 if phoneNumber does not match pattern, else 0.
 */
export function validatePhoneNumber(phoneNumber) {
  if (!phoneNumber || phoneNumber === '') return 1;
  if (!phoneNumberPattern.test(phoneNumber)) return 2;
  return 0;
}

/**
 * @param {string} phoneNumber
 * @returns {number} 1 if phoneNumber is null or empty, 2 if phoneNumber does not match pattern, else 0.
 */
export function validateIcelandicPhoneNumber(phoneNumber) {
  if (!phoneNumber || phoneNumber.length !== 7) return 1;
  if (!icelandicPhoneNumberPattern.test(phoneNumber)) return 2;
  return 0;
}

/**
 * @param {string} text
 * @returns {string} text without any whitespace or dashes
 */
export function removeWhitespacesAndDashes(text) {
  return text.replace(/-| /g, '');
}
