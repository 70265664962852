import DialogTitle from './DialogTitle';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  Divider,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export default function AccountsDialog({ handleClose, isOpen }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Reikningsupplýsingar
      </DialogTitle>

      <DialogContent sx={theme => ({m: '0px', p: theme.spacing(2), pt: '0px'})}>
        <Typography sx={{ mt: '0px' }}>
          Þú getur greitt inn á mál með því að millifæra beint inn á
          bankareikning okkar.
        </Typography>
        <Typography>Athugaðu að það er mikilvægt að setja málanúmer sem tilvísun.</Typography>
        <Divider />
        <Grid container spacing={2} sx={{ m: '10px 0' }}>
          <Grid xs={12} sm={4}>
            <strong>Motus ehf.</strong>
            <Typography variant="body2">0334-26-050226</Typography>
            <Typography variant="body2">Kt: 701195-3109</Typography>
          </Grid>
          <Grid xs={12} sm={4}>
            <strong>Lögheimtan</strong>
            <Typography variant="body2">0111-26-018105</Typography>
            <Typography variant="body2">Kt. 710501-3430</Typography>
          </Grid>
          <Grid xs={12} sm={4}>
            <strong>
              Lögheimtan v/lífeyris
            </strong>
            <Typography variant="body2">0111-26-018104</Typography>
            <Typography variant="body2">Kt. 710501-3430</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

AccountsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
