import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as types from 'src/types';
import { Box, Drawer, Typography, MenuItem, Select, FormControl, InputLabel, OutlinedInput } from '@mui/material/';
import Link from 'next/link';
import { issueTypes, getDescription, PayerPersonalInfoRequest } from 'src/helpers/issueType';
import { issueRole } from 'src/helpers/issueRole';
import { loadOpenClaims } from 'src/api/claimApi';
import { createIssue } from 'src/api/issueApi';
import MultiSelectClaims from 'src/components/FormElements/MultiSelectClaims';
import { Error } from 'src/components/Banners';
import issueStyles from 'styles/issues.module.css';
import MessageForm from './components/MessageForm';

const initialState = {
  message: {
    text: '',
    attachments: [],
  },
  availableClaims: [],
  isSubmittingIssue: false,
  areAttachmentsValid: true,
  error: null,
};

class CreateIssueSideBar extends PureComponent {
  static propTypes = {
    closeCreateIssueDrawer: PropTypes.func.isRequired,
    issuesChangedCallback: PropTypes.func,
    claims: PropTypes.arrayOf(types.claim),
    preSelectedIssueType: PropTypes.string,
    hasContactInfo: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    claims: [],
    preSelectedIssueType: null,
    issuesChangedCallback: () => {}, // do nothing
  };

  constructor(props) {
    super(props);
    const isPersonalInfoRequest = this.props.preSelectedIssueType === PayerPersonalInfoRequest;

    this.state = {
      ...initialState,
      type: props.preSelectedIssueType ? props.preSelectedIssueType : '',
      isPersonalInfoRequest,
      claimIds: props.claims.map((x) => x.claimId),
      hasContactInfo: props.hasContactInfo,
      message: {
        text: isPersonalInfoRequest ? 'Vinsamlegast sendið mér þær persónuupplýsingar sem þið hafið um mig.' : '',
        attachments: [],
        pendingResponseFrom: isPersonalInfoRequest ? issueRole.Personuvernd : issueRole.Greidendathjonusta,
      },
    };
  }

  componentDidMount() {
    loadOpenClaims(
      () => {},
      (data) => {
        this.setState({
          availableClaims: data.claims.filter(
            (x) =>
              !this.props.claims ||
              this.props.claims.length === 0 ||
              x.claimantNumber === this.props.claims[0].claimantNumber
          ),
        });
      },
      () => {
        /* if there is an error fetching available claimnumbers do nothing. 
           User can still create issue for originally selected claim */
      }
    );
  }

  onCreateIssueError = (error) => {
    this.setState({ error });
  };

  onCreateIssueSuccess = () => {
    this.props.issuesChangedCallback();
    return this.props.closeCreateIssueDrawer();
  };

  setIsSubmittingIssue = (isSubmitting) => {
    this.setState({ isSubmittingIssue: isSubmitting });
  };

  handleCreateIssue = (issue) => {
    return createIssue(issue, this.setIsSubmittingIssue, this.onCreateIssueSuccess, this.onCreateIssueError);
  };

  handleAddAttachments = (attachments, isValid) => {
    this.setState((prevState) => {
      return {
        message: {
          ...prevState.message,
          attachments: [...attachments],
        },
        areAttachmentsValid: isValid,
        error: null,
      };
    });
  };

  handleMessageChange = (event) => {
    if (event === undefined || event.target === undefined) {
      return;
    }
    const { value } = event.target;
    this.setState((prevState) => {
      return {
        error: null,
        message: { ...prevState.message, text: value },
      };
    });
  };

  handleTypeChange = (event) => {
    if (event === undefined || event.target === undefined) {
      return;
    }
    this.setState({ type: event.target.value });
  };

  handleConfirm = () => {
    const { type, message, isPersonalInfoRequest, claimIds } = this.state;
    this.setState({ error: null });

    return this.handleCreateIssue({
      type,
      claimIds: isPersonalInfoRequest ? [] : claimIds,
      message,
    });
  };

  handleChangeClaimIds = (claimIds) => {
    this.setState({
      claimIds,
    });
  };

  renderHeader = () => {
    return (
      <>
        <Typography key="headline" variant="h1">
          {this.state.isPersonalInfoRequest ? 'Umsókn um eigin persónuupplýsingar' : 'Ný samskipti'}
        </Typography>
        {!this.state.hasContactInfo && (
          <Box sx={{ pl: '30px' }}>
            <Typography sx={{ fontSize: '12px' }}>
              Ef þú skráir tengiliðsupplýsingar þínar undir{' '}
              <Link
                href="/stillingar/"
                style={{
                  marginTop: '-3px',
                  marginBottom: '-3px',
                  display: 'inline-block',
                  '@media print': {
                    paddingLeft: '0px',
                  },
                  padding: '3px',
                }}
              >
                stillingar
              </Link>{' '}
              geturðu fengið tilkynningu í tölvupósti eða SMS smáskilaboðum þegar þú færð svar við þessari fyrirspurn.
            </Typography>
          </Box>
        )}
      </>
    );
  };

  renderClaims = () => {
    if(this.state.isPersonalInfoRequest || this.state.availableClaims.length === 0) 
      return <></>;
    
    return (
      <MultiSelectClaims
        availableClaims={this.state.availableClaims}
        selectedClaimIds={this.state.claimIds}
        handleChangeClaimIds={this.handleChangeClaimIds}
      />
    )
  }

  renderIssueTypes = () => {
    return this.state.isPersonalInfoRequest ? null : (
      <Box sx={{ p: '15px 30px' }}>
        <FormControl fullWidth>
          <InputLabel id="select-issueType-label">Gerð samskipta</InputLabel>
          <Select
            id="select-issueType"
            labelId="select-issueType-label"
            value={this.state.type}
            onChange={this.handleTypeChange}
            input={<OutlinedInput label="Gerð samskipta" />}
            renderValue={(value) => {
              return <Typography sx={{ m: '5px 15px' }}>{getDescription(value)}</Typography>;
            }}
          >
            {issueTypes(this.state.claimIds).map((item, i) => {
              return (
                <MenuItem key={i} value={item}>
                  {getDescription(item)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  };

  render() {
    const { isSubmittingIssue, areAttachmentsValid, message, type } = this.state;
    const { closeCreateIssueDrawer } = this.props;

    return (
      <Drawer
        key="createNewIssueDrawer"
        anchor="right"
        open={true}
        onClose={closeCreateIssueDrawer}
        className={issueStyles.createIssueDrawer}
      >
        <Box>
          {this.renderHeader()}
          {this.renderClaims()}
          {this.renderIssueTypes()}
          <Box sx={{ p: '15px 30px' }}>
            <MessageForm
              canConfirm={
                (this.state.claimIds.length !== 0 || this.state.isPersonalInfoRequest) &&
                !isSubmittingIssue &&
                areAttachmentsValid &&
                type !== '' &&
                message.text.length > 0
              }
              canAddAttachment={!this.state.isPersonalInfoRequest}
              message={message}
              handleAddAttachments={this.handleAddAttachments}
              handleMessageChange={this.handleMessageChange}
              handleConfirm={this.handleConfirm}
              handleClose={closeCreateIssueDrawer}
            />
          </Box>
          {this.state.error && <Error error={this.state.error} />}
        </Box>
      </Drawer>
    );
  }
}

export default CreateIssueSideBar;
