export function formatSsn(ssn) {
  return ssn && ssn.length === 10
    ? `${ssn.substring(0, 6)}-${ssn.substring(6, 10)}`
    : ssn;
}

export function isSSN(str) {
  const regex = /\d{6}-\d{4}/;
  const regex2 = /\d{10}/;
  return regex.test(str) || regex2.test(str);
}
