import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
  Divider,
} from '@mui/material';

class MultiSelect extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
    inputName: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    inputProps: PropTypes.object,
    inputLabelProps: PropTypes.object,
    renderValue: PropTypes.func,
    renderItem: PropTypes.func,
  };

  static defaultProps = {
    renderValue: (selected) => {
      return typeof selected[0] === 'object'
        ? selected
            .map((x) => {
              return x.name;
            })
            .join(', ')
        : selected.join(', ');
    },
    renderItem: (item) => {
      return typeof item === 'object' ? item.name : item;
    },
    inputProps: {},
    inputLabelProps: {},
  };

  state = {
    open: false,
    selected: this.props.selectedItems || [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.selectedItems !== prevProps.selectedItems) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selected: this.props.selectedItems });
    }
  }

  onChange = (event) => {
    const { value } = event.target;

    // return if "Velja allt / Hreinsa" was clicked
    if (value.indexOf(undefined) !== -1) return;

    this.setState({ selected: value });
    this.props.handleChange(value);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  onOpen = () => {
    this.setState({ open: true });
  };

  selectAll = () => {
    this.setState({ selected: this.props.items });
    this.props.handleChange(this.props.items);
  };

  clearSelection = () => {
    this.setState({ selected: [] });
    this.props.handleChange([]);
  };

  handleControl = (event) => {
    event.stopPropagation();
    if (this.state.selected.length === this.props.items.length) {
      this.clearSelection();
    } else {
      this.selectAll();
    }
  };

  render() {
    return (
      <FormControl fullWidth>
        <InputLabel
          htmlFor={`${this.props.inputName}-multiple`}
          {...this.props.inputLabelProps}
        >
          {this.props.labelText}
        </InputLabel>
        <Select
          multiple
          open={this.state.open}
          value={this.state.selected}
          onChange={this.onChange}
          onOpen={this.onOpen}
          onClose={this.onClose}
          input={
            <OutlinedInput
              name={this.props.inputName}
              label={this.props.labelText}
              id={`${this.props.inputName}-multiple`}
              {...this.props.inputProps}
            />
          }
          renderValue={this.props.renderValue}>
          <MenuItem
            sx={{bgcolor: '#fff !important', fontSize: '13px'}}
            onClick={this.handleControl}
            key="controlAllClear"
          >
            <ListItemText
              primary={
                this.state.selected.length === this.props.items.length
                  ? 'Hreinsa'
                  : 'Velja allt'
              }
            />
          </MenuItem>
          <Divider />
          {this.props.items.map((item, i) => {
            return (
              <MenuItem key={i} value={item} sx={{bgcolor: '#fff !important', fontSize: '13px'}}>
                <Checkbox checked={this.state.selected.indexOf(item) > -1} />
                <ListItemText primary={this.props.renderItem(item)} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

export default MultiSelect;
