import React, { PureComponent } from 'react';
import Alert from '@mui/material/Alert';
import { error as errorType } from 'src/types';

export default class Error extends PureComponent { static propTypes = { error: errorType.isRequired };
  render() {
    return (
      <Alert severity="error" sx={{my: '8px'}}>
        <span>{this.props.error.message}</span>
        {this.props.error.messages && (
          <ul>
            {this.props.error.messages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        )}
      </Alert>
    );
  }
}
