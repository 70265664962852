import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

class Footer extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
  };

  render() {
    const { title, description, icon } = this.props;
    return (
      <Grid xs={12} sm={4} md={3} className="no-print">
        <Typography sx={{color: '#999', fontSize: '14px', textAlign: 'left'}}>
          {title}
        </Typography>
        {icon}
        <Typography sx={{color: '#fff', fontSize: '24px', textAlign: 'left', fontWeight: 200}}>
          {description}
        </Typography>
      </Grid>
    );
  }
}

export default Footer;
