import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import RemoveCircleIcon from '@mui/icons-material/HighlightOff';
import RemoveCircleIconHover from '@mui/icons-material/HighlightOffTwoTone';

export default function RemoveIcon({ removeFile, sx }) {
  const [hasHover, setHasHover] = useState(false);

  return (
    <Box
      onMouseEnter={() => {
        setHasHover(true);
      }}
      onMouseLeave={() => {
        setHasHover(false);
      }}
      sx={{...sx}}
    >
      {hasHover ? (
        <RemoveCircleIconHover
          onClick={() => {
            removeFile();
          }}
        />
      ) : (
        <RemoveCircleIcon
          onClick={() => {
            removeFile();
          }}
        />
      )}
    </Box>
  );
}

RemoveIcon.propTypes = {
  removeFile: PropTypes.func.isRequired,
  style: PropTypes.object,
};

RemoveIcon.defaultProps = {
  style: {},
};
