import { useState, useContext } from 'react';
import Link from 'next/link';

import { signOut } from 'next-auth/react';
import { removeDigitalCollectionTerms, getDigitalCollectionUserPreference } from 'src/helpers/localStorageHelper';
import { useSession } from 'next-auth/react';
import {
  IconButton,
  Box,
  Menu,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Tooltip,
  Typography,
  MenuItem,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import {
  AccountCircle as UserIcon,
  Person as PersonIcon,
  PowerSettingsNew as PowerIcon,
  Close as CloseIcon,
  Settings as SettingsIcon,
  Gavel as TermsIcon,
} from '@mui/icons-material/';

import { AuthContext } from 'src/context/AuthProvider';
import { formatSsn } from 'src/helpers/formatSsn';
import Mosi from 'public/images/moss.png';

export default function SettingsMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const user = useContext(AuthContext);
  const { data: session } = useSession();

  function handleClick(event) {
    event.currentTarget.blur();
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  }

  const handleLogout = async () => {
    if (getDigitalCollectionUserPreference(session.user.id) === 'no') {
      removeDigitalCollectionTerms(session.user.id);
    }
    await signOut({ redirect: false });
    window.location.href = `${process.env.NEXT_PUBLIC_STS_URL}/Account/Logout`;
  };

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <Box>
      <IconButton
        color="primary"
        aria-owns={isOpen ? 'settings-menu' : null}
        aria-haspopup="true"
        aria-label="Stillingar"
        onClick={handleClick}
        sx={(theme) => ({
          p: '8px',
          color: 'primary',
          mx: '4px',
          [theme.breakpoints.down('sm')]: {
            mx: '2px',
            p: '6px',
          },
        })}
        size="large"
      >
        <UserIcon sx={{ fontSize: '32px' }} />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{ m: '0px', mr: '32px' }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            color: 'fff',
          }}
        >
          <Tooltip title="Loka glugga" placement="left">
            <IconButton onClick={handleClose} aria-label="Loka glugga" size="large">
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Card sx={{ width: '400px', maxWidth: '100%', margin: '-8px 0' }}>
          <CardMedia
            component="img"
            alt="Mosi"
            sx={{ height: '160px', objectFit: 'cover' }}
            image={Mosi.src}
            title="Mosi"
          />
          <CardContent sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Avatar
              sx={{
                mt: '-40px',
                background: '#fff',
                width: '60px',
                height: '60px',
                textAlign: 'center',
              }}
            >
              <PersonIcon sx={{ fontSize: '60px' }} color="primary" />
            </Avatar>
            <Typography gutterBottom variant="h5">
              {`Nafn: ${formatSsn(user.name)}`}
            </Typography>
            <Typography gutterBottom variant="h5">
              {`Kennitala: ${formatSsn(user.ssn)}`}
            </Typography>
          </CardContent>
          {user && user.settings && user.settings.existsAsAPayer && (
            <Link href="/stillingar" passHref onMouseUp={handleClose}>
              <MenuItem sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <ListItemAvatar sx={{ minWidth: '32px' }}>
                  <SettingsIcon />
                </ListItemAvatar>
                <ListItemText primary="Stillingar greiðanda" />
              </MenuItem>
            </Link>
          )}
          <Link href="/skilmalar" passHref onMouseUp={handleClose}>
            <MenuItem sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemAvatar sx={{ minWidth: '32px' }}>
                <TermsIcon />
              </ListItemAvatar>
              <ListItemText primary="Skilmálar greiðendavefs" />
            </MenuItem>
          </Link>
          <CardActions>
            <Button variant="contained" fullWidth onClick={handleLogout} sx={{ m: '12px' }}>
              <PowerIcon sx={{ mr: '8px' }} /> Útskráning
            </Button>
          </CardActions>
        </Card>
      </Menu>
    </Box>
  );
}
