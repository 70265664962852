export const defaultErrors = {
  unexpectedCardAuthErrorMessage: 'Gjaldfærsla mistókst. Óvænt villa við kom upp við að auðkenna kortið.',
  payerCancelledErrorMessage: 'Greiðandi hætti við greiðslu.',
  cardAuthenticationFailedErrorMessage: 'Auðkenning korts mistókst.',
  confirmPaymentErrorMessage: 'Gjaldfærsla mistókst. Óvænt villa kom upp.'
}

export const unexpectedCardAuthErrorMessage = { message: defaultErrors.unexpectedCardAuthErrorMessage };
export const payerCancelledErrorMessage = { message: defaultErrors.payerCancelledErrorMessage };
export const confirmPaymentErrorMessage = { message: defaultErrors.confirmPaymentErrorMessage };

export const loadOpenClaimsErrorMessage = {
  message: 'Mistókst að sækja opin mál. Vinsamlegast endurhlaðið síðuna.',
};

export const confirmHomeErrorMessage =
  'Mistókst að uppfæra heimilisfang. Óvænt villa kom upp.';

export const getPaymentOptionsErrorMessage =
  'Villa kom upp við að sækja greiðslumöguleika. Vinsamlegast reynið aftur.';

export const getPaymentPlanErrorMessage =
  'Villa kom upp við að sækja greiðsluáætlun. Vinsamlegast reynið aftur.';

export const createAgreementErrorMessage =
  'Villa kom upp við að stofna samkomulag um greiðsludreifingu. Vinsamlegast reynið aftur.';

export const loadClaimErrorMessage =
  'Villa kom upp við sækja mál. Vinsamlegast endurhlaðið síðuna.';

export const loadError =
  'Mistókst að sækja gögn til bakenda. Vinsamlegast endurhlaðið síðuna.';