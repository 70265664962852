import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Button from '@mui/material/Button';
import FileIcon from '@mui/icons-material/AttachFile';
import { red } from '@mui/material/colors';

import RemoveIcon from 'src/components/Icons/RemoveIcon';
import { allowedMimeTypes } from 'src/helpers/getMimeType';

import issueStyles from 'styles/issues.module.css';

class AddAttachment extends PureComponent {
  maxFileSize = 1572864; // 1,5 MiB

  static propTypes = {
    attachments: PropTypes.array.isRequired,
    addAttachments: PropTypes.func.isRequired,
  };

  state = {
    isSizeValid: true,
    totalSize: 0,
    areAllMimeTypesValid: true,
    invalidAttachments: [],
  };

  // eslint-disable-next-line react/sort-comp
  updateIsValid = (attachments) => {
    const totalSize = attachments.reduce((acc, item) => {
      return acc + item.size;
    }, 0);
    const isSizeValid = totalSize <= this.maxFileSize;
    const invalidAttachments = attachments.filter(
      (attachment) => !allowedMimeTypes.includes(attachment.type)
    );
    const areAllMimeTypesValid = invalidAttachments.length === 0;
    this.setState({
      isSizeValid,
      totalSize,
      areAllMimeTypesValid,
      invalidAttachments,
    });
    return isSizeValid && areAllMimeTypesValid;
  };

  onDrop(acceptedFiles) {
    const { addAttachments } = this.props;
    const attachments = [];
    for (let i = 0; i < acceptedFiles.length; i += 1) {
      const attachment = {
        fileName: acceptedFiles[i].name,
        type: acceptedFiles[i].type,
        size: acceptedFiles[i].size,
        data: [],
      };
      const reader = new FileReader();
      reader.readAsArrayBuffer(acceptedFiles[i]);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;

          const array = new Uint8Array(arrayBuffer);
          for (let j = 0; j < array.length; j += 1) {
            attachment.data.push(array[j]);
          }
        }
      };
      attachments.push(attachment);
    }

    const isValid = this.updateIsValid([
      ...this.props.attachments,
      ...attachments,
    ]);

    addAttachments([...this.props.attachments, ...attachments], isValid);
  }

  removeFile = (file) => {
    const { addAttachments, attachments } = this.props;
    attachments.splice(attachments.indexOf(file), 1);
    const isValid = this.updateIsValid(attachments);
    addAttachments(attachments, isValid);
  };

  renderAttachment = (attachment, index) => {
    return (
      <div key={`attachment_${index}`}>
        <Button size="small" key={index} endIcon={<FileIcon />}>
          {attachment.fileName}
        </Button>
        <RemoveIcon
          sx={{
            color: red[700],
            cursor: 'pointer',
            display: 'inline',
            mt: '3px',
            position: 'absolute',
          }}
          removeFile={() => {
            this.removeFile(attachment);
          }}
        />
      </div>
    );
  };

  renderErrorMessages = () => {
    const { isSizeValid, totalSize, areAllMimeTypesValid, invalidAttachments } =
      this.state;
    const maxFileSizeFormatted =
      Math.round((this.maxFileSize / 1048576) * 10) / 10;
    const totalSizeFormatted = Math.round((totalSize / 1048576) * 10) / 10;

    return (
      <div>
        {!isSizeValid ? (
          <div>
            <strong style={{ color: red[700] }}>
              Viðhengið er of stórt, má vera {maxFileSizeFormatted} MB en er{' '}
              {totalSizeFormatted} MB.
            </strong>
          </div>
        ) : null}

        {!isSizeValid && !areAllMimeTypesValid && <br />}

        {!areAllMimeTypesValid ? (
          <div>
            <strong style={{ color: red[700] }}>
              {invalidAttachments.length === 1
                ? 'Eftirfarandi viðhengi er ekki samþykkt:'
                : 'Eftirfarandi viðhengi eru ekki samþykkt:'}
              <ul>
                {invalidAttachments.map((invalidAttachment) => (
                  <li key={invalidAttachment.fileName}>
                    {invalidAttachment.fileName}
                    {invalidAttachment.type !== '' &&
                      ' ('.concat(invalidAttachment.type).concat(')')}
                  </li>
                ))}
              </ul>
              Leyfð miðilstög fyrir viðhengi eru:
              <ul>
                {allowedMimeTypes.map((allowedMimeType) => (
                  <li key={allowedMimeType}>{allowedMimeType}</li>
                ))}
              </ul>
            </strong>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { attachments } = this.props;

    return (
      <div>
        <Dropzone
          className={issueStyles.dropzone}
          onDrop={(files) => {
            return this.onDrop(files);
          }}
          width="100%"
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div {...getRootProps()} className={issueStyles.dropzoneText}>
                <input {...getInputProps()} />
                <strong>Bæta við viðhengi?</strong>
                <br />
                Dragið hingað eða smellið og veljið eitt eða fleiri.
              </div>
            );
          }}
        </Dropzone>
        {attachments.map((n, i) => {
          return this.renderAttachment(n, i);
        })}
        {this.renderErrorMessages()}
      </div>
    );
  }
}

export default AddAttachment;