import _ from 'lodash';
import {api} from 'src/api/api';
import showFileInBrowser from 'src/helpers/showFileInBrowser';

export function isUserFriendlyError(response) {
  const { status, data } = response;
  if (status >= 500) return false;

  const { errorCode, errorMessage } = data;
  if (!_.isString(errorMessage) || !_.isString(errorCode)) return false;

  const errorCodeNumber = errorCode.split('_').reverse()[0];

  return (
    // eslint-disable-next-line no-restricted-globals
    errorCodeNumber.length === 6 && _.every(errorCodeNumber, (x) => !isNaN(x))
  );
}

export function getAttachmentFromAPI(filename, uri) {
  if (typeof filename === 'undefined') {
    return;
  }
  api
    .get(uri)
    .then((response) => {
      showFileInBrowser(response.data.data, filename);
    })
    .catch((error) => {
      throw error;
    });
}

export function load(url, isLoading, onSuccess, onError) {
  isLoading(true);
  return api
    .get(url)
    .then((response) => {
      if (isUserFriendlyError(response)) onError();
      else onSuccess(response.data);
      isLoading(false);
    })
    .catch(() => {
      onError();
      isLoading(false);
    });
}
