export function formatAmountToString(number) {
  if (number === undefined || number === null) {
    return number;
  }
  return Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function formatAmountToISKCurrencyString(number) {
  if (number === undefined || number === null) {
    return number;
  }
  return `${Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} kr.`;
}

export function formatAmountToOtherCurrencyString(number, currencyCode) {
  if (number === undefined || number === null) {
    return number;
  }
  return `${number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} ${currencyCode}`;
}
