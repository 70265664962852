const setItem = (name, value) => {
  if (typeof window !== 'undefined') localStorage.setItem(name, value);
};

export const getItem = (name) => {
  return typeof window !== 'undefined' ? localStorage.getItem(name) : null;
};

export const getInfoDialogClosed = () => {
  return getItem('infoDialogClosed');
};

export const setInfoDialogClosed = (value) => {
  setItem('infoDialogClosed', value);
};

export const getTermsAndAgreement = (userId) => {
  return getItem('termsAndAgreementV2' + userId);
};

export const setTermsAndAgreement = (userId, value) => {
  setItem('termsAndAgreementV2' + userId, value);
};

export const removeTerms = (userId) => {
  localStorage.removeItem('termsAndAgreementV2' + userId);
};

export const setDigitalCollectionTerms = (userId, value) => {
  setItem('digitalCollectionTerms' + userId, value);
};

export const getDigitalCollectionTerms = (userId) => {
  return getItem('digitalCollectionTerms' + userId);
};

export const setDigitalCollectionUserPreference = (userId, value) => {
  setItem('digitalCollectionUserPreference' + userId, value);
};

export const getDigitalCollectionUserPreference = (userId) => {
  return getItem('digitalCollectionUserPreference' + userId);
};

export const removeDigitalCollectionTerms = (userId) => {
  localStorage.removeItem('digitalCollectionTerms' + userId);
};

export const setTemporaryPass = (userId, value) => {
  setItem('cacheDate' + userId, value);
};

export const getTemporaryPass = (userId) => {
  return getItem('cacheDate' + userId);
};
