import React, { Component } from 'react';
import Head from 'next/head'
import Box from '@mui/material/Box';
import moment from 'moment';
import 'moment/locale/is';
import Footer from 'src/components/MainLayout/Footer';
import AuthenticatedAppBar from 'src/components/MainLayout/AppBar/AuthenticatedAppBar';

// set locale to icelandic
moment.locale('is');

export default class Layout extends Component {
  render() {
    const { children } = this.props;
    return (
      <Box id="ssas" sx={{ 
        bgcolor: '#f0f0f0', 
        px: '16px', 
        '@media print': { pt: '0px' }
      }}>
        <Head>
          <title>Greiðendavefur Motus</title>
        </Head>
        <AuthenticatedAppBar />
        <Box component="main" sx={(theme) => ({
          background: '#f0f0f0',
          px: '16px',
          pt: '60px',
          '@media print': {
            pt: '0px'
          },
          paddingBottom: '20px',
          flexGrow: 1,
          minHeight: '60vh',
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }),
          ml: '0px'
        })}>
          {children}
        </Box>
        <Box sx={(theme) => ({
          flexGrow: 1,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }),
          ml: '0px'
        })}>
          <Footer />
        </Box>
      </Box>
    );
  }
}