import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Typography } from '@mui/material/';
import ProductAvatar from 'src/components/Avatars/ProductAvatar';
import { formatAmountToISKCurrencyString } from 'src/helpers/formatAmountToString';
import issueStyles from 'styles/issues.module.css';

import MultiSelect from './MultiSelect';

export default function MultiSelectClaims({ availableClaims, selectedClaimIds, handleChangeClaimIds }) {
  if (availableClaims.length === 0) return <></>;

  return (
    <Box sx={{ p: '15px 30px' }}>
      <MultiSelect
        labelText={selectedClaimIds.length === 0 ? 'Veldu mál' : 'Valin mál'}
        inputProps={{
          classes: { input: issueStyles.issueTypesInput },
        }}
        inputName="issueTypes-select"
        items={availableClaims.map((x) => x.claimId)}
        selectedItems={selectedClaimIds}
        handleChange={handleChangeClaimIds}
        renderValue={(selected) =>
          selectedClaimIds.length === availableClaims.length ? (
            <Typography variant="body1">Öll</Typography>
          ) : (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {selected.map((value) => {
                const claim = availableClaims.filter((x) => x.claimId === value)[0];
                return (
                  <Chip
                    avatar={
                      <ProductAvatar
                        type={claim?.product}
                        showName={false}
                        isChip={true}
                        sx={{
                          float: 'left',
                          ml: '10px',
                          width: '20px',
                          height: '20px',
                          fontSize: '12px',
                          fontWeight: 'normal',
                        }}
                      />
                    }
                    key={value}
                    label={claim?.claimNumber}
                    sx={{
                      m: '2px',
                      fontSize: '1rem',
                      root: {
                        label: { pl: '5px' },
                      },
                    }}
                  />
                );
              })}
            </Box>
          )
        }
        renderItem={(claimId) => {
          const claim = availableClaims.filter((x) => x.claimId === claimId)[0];
          return (
            <Box
              component="span"
              key={claim.claimId}
              value={claim.claimId}
              sx={{
                fontWeight: selectedClaimIds.indexOf(claimId) === -1 ? 400 : 600,
                display: 'inline-flex',
              }}
            >
              <ProductAvatar
                type={claim.product}
                showName={false}
                isChip={true}
                sx={{
                  float: 'left',
                  ml: '6px',
                  mr: '5px',
                  width: '20px',
                  height: '20px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                }}
              />
              <Typography variant="body1">{claim.claimNumber}</Typography>
              <Typography variant="body1" sx={{ ml: '10px', mt: '2px', fontSize: 'smaller' }}>
                {claim.claimantName}
              </Typography>
              <Typography variant="body1" sx={{ ml: '10px', mt: '2px', fontSize: 'smaller' }}>
                {formatAmountToISKCurrencyString(claim.totalRemaining)}
              </Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
}

MultiSelectClaims.propTypes = {
  availableClaims: PropTypes.array.isRequired,
  selectedClaimIds: PropTypes.array.isRequired,
  handleChangeClaimIds: PropTypes.func.isRequired,
};
