import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import InfoIcon from '@mui/icons-material/Info';
import Image from 'next/image';
import { AuthContext } from 'src/context/AuthProvider';
import MotusLogo from 'public/images/motus_logo_hvitt.svg';
import CreateIssueSideBar from 'src/containers/CreateIssueSideBar';
import { CommunicationContext } from 'src/context/CommunicationProvider';
import { PayerPersonalInfoRequest } from 'src/helpers/issueType';
import AccountDialog from 'src/components/Modals/AccountsDialog';
import ProductList from './ProductList';
import FooterBox from './FooterBox';

function FooterListItem({ children }) {
  return (
    <Box
      component="li"
      sx={(theme) => ({
        float: 'left',
        borderLeft: '1px rgba(255, 255, 255, .12) solid',
        p: '5px 0px 5px 12px',
        ml: '12px',
        color: '#999',
        [theme.breakpoints.down('lg')]: {
          borderLeft: 'none',
          ml: '0px',
          p: '2px 0px',
          float: 'none',
          display: 'block',
        },
        '@media print': {
          float: 'left',
          p: '5px 15px 5px 0px',
          m: '0px',
        },
      })}
    >
      {children}
    </Box>
  );
}

export default function Footer() {
  const [isIssueDrawerOpen, setIsIssueDrawerOpen] = useState(false);
  const [shouldShowAccountInfo, setShouldShowAccountInfo] = useState(false);

  const communicationContext = useContext(CommunicationContext);
  const user = useContext(AuthContext);

  function handleOpenCreateIssueDrawer() {
    setIsIssueDrawerOpen(true);
  }

  function handleCloseCreateIssueDrawer() {
    setIsIssueDrawerOpen(false);
  }

  function issuesChangedCallback() {
    communicationContext.refreshIssues();
  }

  function handleOpenAccountInfoDialog() {
    setShouldShowAccountInfo(true);
  }

  function handleCloseAccountInfoDialog() {
    setShouldShowAccountInfo(false);
  }

  const year = new Date().getFullYear();

  const { isLoggedIn, needsToAcceptTerms, settings } = useContext(AuthContext);

  return (
    <>
      {isIssueDrawerOpen && (
        <CreateIssueSideBar
          closeCreateIssueDrawer={handleCloseCreateIssueDrawer}
          issuesChangedCallback={issuesChangedCallback}
          preSelectedIssueType={PayerPersonalInfoRequest}
          hasContactInfo={user.settings.hasContactInfo}
        />
      )}
      <ProductList />
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          p: '45px 25px',
          mx: '-16px',
          bgcolor: '#212121',
          '@media print': {
            borderTop: '1px solid rgba(224, 224, 224, 1) !important',
            m: '30px 0 10px 0 !important',
            p: '0px',
          },
          [theme.breakpoints.down('md')]: {
            p: '45px 15px',
          },
        })}
      >
        <Grid container spacing={2}>
          <FooterBox
            title="Þjónustuver greiðanda"
            description="440 7700"
            icon={<PhoneIcon sx={{ color: '#fff', fontSize: '28px', float: 'left', mr: '6px' }} />}
          />
          <FooterBox
            title="Skiptiborð"
            description="440 7000"
            icon={<PhoneIcon sx={{ color: '#fff', fontSize: '28px', float: 'left', mr: '6px' }} />}
          />
          <FooterBox
            title="Afgreiðslutími virka daga"
            description="10:00-16:00"
            icon={<HomeIcon sx={{ color: '#fff', fontSize: '28px', float: 'left', mr: '6px' }} />}
          />

          <Grid xs={12} sm={6} md className="no-print" />
          <Grid
            xs={12}
            sx={{
              m: '20px auto',
              borderTop: '1px rgba(255, 255, 255, .12) solid',
              borderBottom: '1px rgba(255, 255, 255, .12) solid',
            }}
          >
            <Image src={MotusLogo} className="footerLogo" alt="Motus ehf." />
            <Box
              component="ul"
              sx={(theme) => ({
                listStyle: 'none',
                color: '#fff',
                fontSize: 15,
                m: '38px 0 0 0',
                p: 0,
                [theme.breakpoints.down('lg')]: { my: '15px' },
                '@media print': { mt: '0px' },
              })}
            >
              <FooterListItem>&copy; {year} Motus ehf.</FooterListItem>
              <FooterListItem>Katrínartúni 4</FooterListItem>
              <FooterListItem>105 Reykjavík</FooterListItem>
              <FooterListItem>
                <Box
                  component="a"
                  href="mailto:motus@motus.is"
                  sx={{
                    color: '#999',
                    '&:hover': {
                      color: '#8fb16f',
                    },
                  }}
                >
                  motus@motus.is
                </Box>
              </FooterListItem>
              <FooterListItem>
                <Box
                  component="button"
                  type="button"
                  sx={{
                    background: 'none!important',
                    border: 'none!important',
                    padding: '0px!important',
                    color: '#999!important',
                    cursor: 'pointer',
                    font: 'inherit',
                  }}
                  onClick={handleOpenAccountInfoDialog}
                >
                  Reikningsupplýsingar
                  <InfoIcon sx={{ ml: '3px', mb: '-6px' }} />
                </Box>
              </FooterListItem>
              {isLoggedIn && !needsToAcceptTerms && settings && settings.existsAsAPayer && (
                <FooterListItem>
                  <Box
                    component="button"
                    type="button"
                    sx={{
                      background: 'none!important',
                      border: 'none!important',
                      padding: '0px!important',
                      color: '#999!important',
                      cursor: 'pointer',
                      font: 'inherit',
                    }}
                    onClick={handleOpenCreateIssueDrawer}
                  >
                    Persónuupplýsingar
                    <InfoIcon sx={{ ml: '3px', mb: '-6px' }} />
                  </Box>
                </FooterListItem>
              )}
            </Box>
          </Grid>
        </Grid>
        <AccountDialog handleClose={handleCloseAccountInfoDialog} isOpen={shouldShowAccountInfo} />
      </Box>
    </>
  );
}
