import React, { useState } from 'react';
import { loadIssues } from 'src/api/issueApi';

export const CommunicationContext = React.createContext();

export function CommunicationProvider({ children }) {
  const [issues, setIssues] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function onSuccess(dataIssues) {
    if (error) setError(null);
    setIssues(dataIssues);
  }

  function onError() {
    setError({
      message: 'Mistókst að sækja samskipti. Vinsamlegast endurhlaðið síðuna.',
    });
  }

  function refreshIssues() {
    loadIssues(setIsLoading, onSuccess, onError);
  }

  return (
    <CommunicationContext.Provider
      value={{
        issues,
        error,
        isLoading,
        refreshIssues: () => refreshIssues(),
      }}
    >
      {children}
    </CommunicationContext.Provider>
  );
}
