import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { AuthContext } from 'src/context/AuthProvider';

import ProductAvatar from '../Avatars/ProductAvatar';

export default function ProductList() {
  const auth = useContext(AuthContext);

  return auth.isLoggedIn && !auth.needsToAcceptTerms && auth.settings ? (
    <Box
      sx={{
        textAlign: 'center',
        backgroundColor: '#f0f0f0',
        mt: '48px',
        mb: '32px',
        '@media print': { display: 'none' },
      }}
    >
      <Box sx={{ fontSize: '1em', fontWeight: 500, p: '15px', display: 'inline-block' }}>
        <ProductAvatar type="PrimaryCollection" showName />
      </Box>
      <Box sx={{ fontSize: '1em', fontWeight: 500, p: '15px', display: 'inline-block' }}>
        <ProductAvatar type="SecondaryCollection" showName />
      </Box>
      <Box sx={{ fontSize: '1em', fontWeight: 500, p: '15px', display: 'inline-block' }}>
        <ProductAvatar type="LegalCollection" showName />
      </Box>
      <Box sx={{ fontSize: '1em', fontWeight: 500, p: '15px', display: 'inline-block' }}>
        <ProductAvatar type="DebtSurveillance" showName />
      </Box>
      <Box sx={{ fontSize: '1em', fontWeight: 500, p: '15px', display: 'inline-block' }}>
        <ProductAvatar type="ForeignCollection" showName />
      </Box>
    </Box>
  ) : null;
}
