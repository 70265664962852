import { useState } from 'react';
import DialogTitle from './DialogTitle';
import PropTypes from 'prop-types';
import { Modal, DialogContent, Divider, Paper, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { updateDigitalCollection } from 'src/api/payerApi';
import { Error } from 'src/components/Banners';

export default function DigitalCollectionTermsDialog({ handleClose, isOpen }) {
  const [error, setError] = useState(null);

  const handleConfirmation = () => {
    updateDigitalCollection(true, onSendSuccess, onSaveError);
  };
  const handleDecline = () => {
    updateDigitalCollection(false, onSendSuccess, onSaveError);
  };

  const onSendSuccess = (acceptedDate) => {
    if (acceptedDate === '') {
      handleClose(null, 'Declined', undefined);
    } else {
      handleClose(null, 'Accepted', acceptedDate);
    }
  };

  const onSaveError = () => {
    setError('Villa kom upp við að staðfesta. Vinsamlegast reyndu aftur.');
  };
  return (
    <Modal
      onClose={(event) => handleClose(event, 'backdropClick')}
      open={isOpen}
      sx={{ overflow: 'scroll' }}
      disableEscapeKeyDown
    >
      <Paper sx={{ padding: '40px', maxWidth: '800px', margin: '60px auto' }}>
        <Grid>
          <DialogTitle
            fontWeight="bolder"
            id="customized-dialog-title"
            onClose={(event) => handleClose(event, 'backdropClick')}
          >
            Viltu fá tölvupóst í stað bréfpósts?
          </DialogTitle>
        </Grid>
        <Divider />
        <DialogContent>
          <Grid mb="50px">
            <Typography variant="h4">
              Við getum sent þér innheimtubréf í tölvupósti í stað bréfpósts. Þannig færðu bréf vegna skulda sem kunna
              að koma til innheimtu hjá Motus og Lögheimtunni fyrr en ella og getur brugðist hraðar við.
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button sx={{ backgroundColor: '#eeeeee', color: '#000000', mr: '10px' }} onClick={handleDecline}>
              NEI TAKK
            </Button>
            <Button variant="contained" onClick={handleConfirmation}>
              JÁ TAKK
            </Button>
          </Grid>
          {error && (
            <Grid xs={12}>
              <Error error={{ message: error }} />
            </Grid>
          )}
        </DialogContent>
      </Paper>
    </Modal>
  );
}

DigitalCollectionTermsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
