import Head from 'next/head';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { AuthProvider } from 'src/context/AuthProvider';
import { SessionProvider } from 'next-auth/react';
import { CommunicationProvider } from 'src/context/CommunicationProvider';
import Layout from 'src/components/MainLayout/Layout';
import AuthGuard from 'src/components/AuthGuard';
import createEmotionCache from 'styles/createEmotionCache';
import theme from 'styles/theme';
import 'styles/global.css';
import { initalizeMonitoring } from 'infrastructure/monitoring';
import { getSession } from 'next-auth/react';
import FrontChat from 'src/components/FrontChat';

initalizeMonitoring();

const clientSideEmotionCache = createEmotionCache();

function App(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, session } = props;
  return (
    <SessionProvider session={session} refetchInterval={5 * 60}>
      <AuthProvider>
        <CommunicationProvider>
          <CacheProvider value={emotionCache}>
            <Head>
              <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Head>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <CssBaseline />
                  <Layout>
                    {Component.isPublicPage ? (
                      <Component {...pageProps} />
                    ) : (
                      <AuthGuard>
                        <Component {...pageProps} />
                      </AuthGuard>
                    )}
                    {session && (
                      <FrontChat
                        id={session.user.id}
                        email={session.user.email}
                        name={session.user.name}
                        ssn={session.user.ssn}
                      />
                    )}
                  </Layout>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </CacheProvider>
        </CommunicationProvider>
      </AuthProvider>
    </SessionProvider>
  );
}

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};
  const session = await getSession(ctx);

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps, session };
};

App.propTypes = {
  pageProps: PropTypes.object.isRequired,
  Component: PropTypes.func.isRequired,
  session: PropTypes.object,
};

export default App;
