import { api } from 'src/api/api';
import { load } from 'src/helpers/apiHelper';

const url = '/api/payer/';

function apiPut(endpoint, body, onSuccess, onError) {
  return api.put(`${url}${endpoint}`, body).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onError(error.response);
    }
  );
}

function apiPost(endpoint, body, onSuccess, onError) {
  return api.post(`${url}${endpoint}`, body).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onError(error.response);
    }
  );
}

function apiDelete(endpoint, onSuccess, onError) {
  return api.delete(`${url}${endpoint}`).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onError(error.response);
    }
  );
}

export function loadSettings(onSuccess, onError) {
  return load(`${url}settings`, () => {}, onSuccess, onError);
}

export function loadEmails(onSuccess, onError) {
  return load(`${url}emails`, () => {}, onSuccess, onError);
}

export function loadPhones(onSuccess, onError) {
  return load(`${url}phones`, () => {}, onSuccess, onError);
}

export function loadHome(onSuccess, onError) {
  return load(`${url}home`, () => {}, onSuccess, onError);
}

export function verify(onSuccess, onError) {
  return apiPut('verify', {}, onSuccess, onError);
}

export function updateEmail(body, onSuccess, onError) {
  return apiPut('email', body, onSuccess, onError);
}

export function updatePhone(body, onSuccess, onError) {
  return apiPut('phone', body, onSuccess, onError);
}

export function createEmail(body, onSuccess, onError) {
  return apiPost('email', body, onSuccess, onError);
}

export function createPhone(body, onSuccess, onError) {
  return apiPost('phone', body, onSuccess, onError);
}

export function deleteEmail(id, onSuccess, onError) {
  return apiDelete(`email/${id}`, onSuccess, onError);
}

export function deletePhone(id, onSuccess, onError) {
  return apiDelete(`phone/${id}`, onSuccess, onError);
}
export function sendEmailConfirmation(id, onSuccess, onError) {
  return apiPost(`emailConfirmation/${id}`, null, onSuccess, onError);
}
export function confirmEmail(id, token, onSuccess, onError) {
  return apiPost(`email/${id}/confirm/${token}`, null, onSuccess, onError);
}
export function updateDigitalCollection(accepted, onSuccess, onError) {
  return apiPut(`contact/digitalCollection/${accepted}`, null, onSuccess, onError);
}
export function logError(body, onSuccess, onError) {
  return apiPost(`error`, body, onSuccess, onError);
}